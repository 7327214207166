// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-pagination-main {
	background-color: #fff;
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	padding: 2px 9px;
}

/* ContentPagination.css */
button.active {
	color: red; /* Set your desired text color for the active state */
	background-color: blue;
}

.pagination-btn-div {
	display: flex;
	width: 68%;
	align-items: center;
	font-size: 14px;
	gap: 3%;
	margin-left: 3%;
}

.pagination-btn-div > p {
	padding: 1px;
}

.pagination-btn-div button {
	border: none;
	background-color: #fff;
}

.pagination-btn-div button > img {
	width: 80%;
	color: blue;
}

.sort-option {
	font-size: 14px;
}

.sort-option > select {
	border: none;
	outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/ContentPagination/ContentPagination.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,aAAa;CACb,oCAAoC;CACpC,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA,0BAA0B;AAC1B;CACC,UAAU,EAAE,qDAAqD;CACjE,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,UAAU;CACV,mBAAmB;CACnB,eAAe;CACf,OAAO;CACP,eAAe;AAChB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,sBAAsB;AACvB;;AAEA;CACC,UAAU;CACV,WAAW;AACZ;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,YAAY;CACZ,aAAa;AACd","sourcesContent":[".content-pagination-main {\n\tbackground-color: #fff;\n\tdisplay: flex;\n\t/* justify-content: space-between; */\n\talign-items: center;\n\tpadding: 2px 9px;\n}\n\n/* ContentPagination.css */\nbutton.active {\n\tcolor: red; /* Set your desired text color for the active state */\n\tbackground-color: blue;\n}\n\n.pagination-btn-div {\n\tdisplay: flex;\n\twidth: 68%;\n\talign-items: center;\n\tfont-size: 14px;\n\tgap: 3%;\n\tmargin-left: 3%;\n}\n\n.pagination-btn-div > p {\n\tpadding: 1px;\n}\n\n.pagination-btn-div button {\n\tborder: none;\n\tbackground-color: #fff;\n}\n\n.pagination-btn-div button > img {\n\twidth: 80%;\n\tcolor: blue;\n}\n\n.sort-option {\n\tfont-size: 14px;\n}\n\n.sort-option > select {\n\tborder: none;\n\toutline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
