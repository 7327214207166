// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogDeleteMainButtonDiv {
  /* border: 1px solid red; */
  margin-left: 3%;
}

.blogDeleteButton {
  border: none;
  background-color: #fc4619;
  color: #fff;
  padding: 4px 8px;
  border-radius: 5px;
}

.blogDeleteButton:hover {
  background-color: white;
  border: 1px solid #ef4c23;
  color: #ef4c23;
}

.blogDeletePopUpButton {
  border: none;
  background-color: #fc4619;
  color: #fff;
  padding: 4px 8px;
  border-radius: 5px;
  width: 20%;

}

.blogDeletePopUpButton:hover {
  background-color: white;
  border: 1px solid #ef4c23;
  color: #ef4c23;
}


`, "",{"version":3,"sources":["webpack://./src/components/Pages/ConfirmationModal/ConfirmationModal.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;;AAEZ;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".blogDeleteMainButtonDiv {\n  /* border: 1px solid red; */\n  margin-left: 3%;\n}\n\n.blogDeleteButton {\n  border: none;\n  background-color: #fc4619;\n  color: #fff;\n  padding: 4px 8px;\n  border-radius: 5px;\n}\n\n.blogDeleteButton:hover {\n  background-color: white;\n  border: 1px solid #ef4c23;\n  color: #ef4c23;\n}\n\n.blogDeletePopUpButton {\n  border: none;\n  background-color: #fc4619;\n  color: #fff;\n  padding: 4px 8px;\n  border-radius: 5px;\n  width: 20%;\n\n}\n\n.blogDeletePopUpButton:hover {\n  background-color: white;\n  border: 1px solid #ef4c23;\n  color: #ef4c23;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
