import React from 'react'
import { Navigate } from "react-router-dom";
import { getToken } from 'src/components/utils/storage';

const PrivateRoute = ({children}) => {
  const user= JSON.parse(localStorage.getItem('user'))
  // console.log(token)

  if (!user?.token) {
    return <Navigate to={"/"} />;
  }
  return <>{children}</>;
}

export default PrivateRoute