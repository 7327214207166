import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from "react";
import "./ConfirmationModal.css"

const ConfirmationModal = ({ isOpen, setIsOpen, deleteBlog, id }) => {
  const handleYes = () => {
    deleteBlog(id);
    setIsOpen(false);
  };

  return (
    <Modal style={{
      marginTop:"10%",marginLeft:"-5%"
    }}
      show={isOpen}
      onHide={() => {
        setIsOpen(false);
      }}
      animation={false}
      backdrop={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Do you really want to delete the component data confirm?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer >

      <Button
          className="blogDeletePopUpButton"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          No
        </Button>


        <Button className="blogDeletePopUpButton" onClick={handleYes}>
          Yes
        </Button>
      
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
