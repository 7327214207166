import React from 'react'

const ContentFilter = ({categoryData}) => {
  return (
    <>
      <select >
        {categoryData?.map((el)=><option>{el.category}</option>)}

        </select>
    </>
  )
}

export default ContentFilter
