import React from 'react';
import './ContentHeader.css';
import ContentPagination from '../ContentPagination/ContentPagination';

const ContentHeader = (props) => {
  return (
    <>
    <div className='Header-Outer-container'>
        <h1>
            {props.title}
        </h1>
        <div className='right-container'>
        <div class='search-box-wrapper'>
          <input type="text" id="searchInput" placeholder="Search.." />
          <div id='submitsearch'
            >
            <span>Search</span>
          </div>
        </div>
            <div className='notification'>
            <img src="/navicons/icons8-notification-50.png" alt='notification'/>
            </div>
        </div>
    </div>
    </>
  )
}

export default ContentHeader