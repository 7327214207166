import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./BlogEditor.css";
import {
	DeleteBlogv2ById,
	getAllBlogsv2,
	getAllCategory,
} from "src/services/apiServices";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Alert from "react-bootstrap/Alert";
import ContentHeader from "../ContentHeader/ContentHeader";
import { CHeaderDivider } from "@coreui/react";
import ContentPagination from "../ContentPagination/ContentPagination";

const BlogEditor = () => {
	const [getAllBlogsData, setAllBlogsData] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [blogId, setBlogId] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState("");
	const [pageData, setPageData] = useState({
		page: 1,
		blogCount: 0,
	});
	const [getAllCategoryData, setAllCategoryData] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		getBlogsData(pageData?.page, selectedCategory);
	}, [pageData?.page, selectedCategory]);

	const handlePagination = (type, pageNumber) => {
		if (type === "next") {
			setPageData((prev) => ({ ...prev, page: prev.page + 1 }));
		} else if (type === "prev") {
			setPageData((prev) => ({ ...prev, page: prev.page - 1 }));
		} else if (type === "specific") {
			setPageData((prev) => ({ ...prev, page: pageNumber }));
		}
	};

	const handleCategoryChange = (category) => {
		setSelectedCategory(category);
		setPageData((prev) => ({ ...prev, page: 1 })); // Reset page to 1 when category changes
	};

	const getBlogsData = async (page, category) => {
		setShowAlert(false);
		let response = await getAllBlogsv2(page, category);
		let categoryres = await getAllCategory();
		setAllBlogsData(response.data.blogs);
		setPageData({
			...pageData,
			blogCount: response?.data?.blogCount,
		});
		setAllCategoryData(categoryres?.data?.category);
	};

	const deleteBlog = async (id) => {
		const response = await DeleteBlogv2ById(id);
		setDeleteMessage(response?.data?.message);
		setShowAlert(true);
		getBlogsData(pageData?.page, selectedCategory);
	};

	return (
		<>
			<ContentHeader title={"Blogs Section"} />
			<CHeaderDivider />

			<div className="mainblogsectionboxes">
				<div className="mainblogflexleftbox">
					{getAllBlogsData &&
						getAllBlogsData.length > 0 &&
						getAllBlogsData.map((ele) => (
							<div className="mainblogagainflexbox" key={ele._id}>
								<div className="mainblogagainleftboxflex">
									<form>
										<div className="checkboxesing">
											<input type="checkbox" />
											<h5 className="headingtextboxesh4">
												{ele.BlogHeading}
											</h5>
										</div>
										<p className="paragraphmainbox">
											{ele.BlogSubHeading}
										</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												navigate(
													"/blogv2?blogid=" + ele._id
												);
											}}
											className="edit-btn"
										>
											Edit
										</button>
										<button
											className="delete-btn"
											onClick={(e) => {
												e.preventDefault();
												setIsOpen(true);
												setBlogId(ele._id);
											}}
										>
											Delete
										</button>
									</form>
								</div>

								<div className="mainblogflexagainrightboxforimage">
									<img src={ele.ImageURL} alt="image" />
								</div>
							</div>
						))}

					<ContentPagination
						categoryData={getAllCategoryData}
						handlePagination={handlePagination}
						handleCategoryChange={handleCategoryChange}
						selectedCategory={selectedCategory}
						page={pageData?.page}
						pageCount={pageData?.blogCount}
					/>
				</div>

				{isOpen && (
					<ConfirmationModal
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						deleteBlog={deleteBlog}
						id={blogId}
					/>
				)}

				<div
					onClick={() => navigate("/blogv2")}
					style={{ cursor: "pointer" }}
					className="mainblogflexrightbox"
				>
					<div>
						<h5>Add A New Blog</h5>
					</div>
					<div className="svfimgage">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="25"
							height="25"
							fill="currentColor"
							className="bi bi-chevron-right"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					</div>
				</div>

				{showAlert && (
					<Alert key="success" variant="success">
						{deleteMessage}
					</Alert>
				)}
			</div>
		</>
	);
};

export default BlogEditor;
