import React from "react";
import "./ContentPagination.css";
import ContentFilter from "./ContentFilter";

const ContentPagination = ({
	page,
	pageCount,
	handlePagination,
	handleCategoryChange,
	selectedCategory,
	categoryData,
}) => {
	const maxPagesToShow = 10;

	const totalPages = Math.ceil(pageCount / 5);

	const renderPageNumbers = () => {
		const pageNumbers = [];
		const startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
		const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(
				<button
					key={i}
					onClick={() => handlePagination("specific", i)}
					className={i === page ? "active" : ""}
				>
					{i}
				</button>
			);
		}
		return pageNumbers;
	};

	return (
		<div className="content-pagination-main">
			<div className="sort-option">
				Sort:
				<ContentFilter
					categoryData={categoryData}
					handleCategoryChange={handleCategoryChange}
					selectedCategory={selectedCategory}
				/>
			</div>

			<div className="pagination-btn-div">
				Page: {page} of {totalPages}
				<button
					onClick={() => handlePagination("prev")}
					disabled={page === 1}
				>
					<img
						src="/navicons/icons8-chevron-left-30.png"
						alt="chevron-left"
					/>
				</button>
				{renderPageNumbers()}
				<button
					disabled={totalPages === page}
					onClick={() => handlePagination("next")}
				>
					<img
						src="/navicons/icons8-chevron-right-30.png"
						alt="chevron-right"
					/>
				</button>
			</div>
		</div>
	);
};

export default ContentPagination;
