// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header-Outer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.4rem;

  background-color:rgb(255, 247, 247);
  margin-left: -10px;
  margin: auto;
  
}
.Header-Outer-container>h1{
  font-size: 28px;
}
.right-container {
  display: flex;
  justify-content: space-between;
}
#submitsearch {
  border: 1px solid rgb(138, 134, 134);
  margin-left: -82px;
  padding: 5px;
  border-radius: 19px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 8px;
  padding-top: 4px;

  display: none;
  box-shadow: 0 0 1px black;
  margin-right: 110px;
}

#searchInput {
  border: 1px solid rgb(255, 193, 206);
  border-radius: 15px;
  font-size: 16px;
  background-color: white;
  background-image: url("https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-24.png");
  background-position: 10px 7px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 40px;
  transition: width 0.8s ease-in-out;
  outline: none;
  opacity: 0.9;
}

.notification{
 height: 4rem;
padding: 5px;
}
.notification>img{
 width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/ContentHeader/ContentHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,eAAe;;EAEf,mCAAmC;EACnC,kBAAkB;EAClB,YAAY;;AAEd;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;;EAEhB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,4FAA4F;EAC5F,6BAA6B;EAC7B,4BAA4B;EAC5B,0BAA0B;EAE1B,kCAAkC;EAClC,aAAa;EACb,YAAY;AACd;;AAEA;CACC,YAAY;AACb,YAAY;AACZ;AACA;CACC,UAAU;AACX","sourcesContent":[".Header-Outer-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  padding: 0.4rem;\n\n  background-color:rgb(255, 247, 247);\n  margin-left: -10px;\n  margin: auto;\n  \n}\n.Header-Outer-container>h1{\n  font-size: 28px;\n}\n.right-container {\n  display: flex;\n  justify-content: space-between;\n}\n#submitsearch {\n  border: 1px solid rgb(138, 134, 134);\n  margin-left: -82px;\n  padding: 5px;\n  border-radius: 19px;\n  cursor: pointer;\n  padding-left: 10px;\n  padding-right: 8px;\n  padding-top: 4px;\n\n  display: none;\n  box-shadow: 0 0 1px black;\n  margin-right: 110px;\n}\n\n#searchInput {\n  border: 1px solid rgb(255, 193, 206);\n  border-radius: 15px;\n  font-size: 16px;\n  background-color: white;\n  background-image: url(\"https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-24.png\");\n  background-position: 10px 7px;\n  background-repeat: no-repeat;\n  padding: 8px 20px 8px 40px;\n  -webkit-transition: width 0.8s ease-in-out;\n  transition: width 0.8s ease-in-out;\n  outline: none;\n  opacity: 0.9;\n}\n\n.notification{\n height: 4rem;\npadding: 5px;\n}\n.notification>img{\n width: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
