import axios from "axios";

const BaseURL = "https://backendadmin.bottomfunnel.net/api/v1";

export const uploadingImage = async (data) => {
  const informationURL = BaseURL + "/upload/image";
  const response = await axios.put(informationURL, data);
  return response;
};

export const downloadImage = async (key) => {
  const informationURL = BaseURL + `/images/${key}`;
  const response = await axios.get(informationURL);
  return response;
};

export const addANewBlog = async (data) => {
  const informationURL = BaseURL + "/blog";
  const response = await axios.post(informationURL, data);
  return response;
};

export const getAllBlogs = async (page) => {
  const informationURL = BaseURL +`/blog?page=${page}`;
  const response = await axios.get(informationURL);
  return response;
};

export const getBlog = async (key) => {
  const informationURL = BaseURL + `/blog/${key}`;
  const response = await axios.get(informationURL);
  return response;
}

export const deleteBlogById = async (key) => {
  const informationURL = BaseURL + `/blog/${key}`;
  const response = await axios.delete(informationURL);
  return response;
}

export const updateBlogById=async(key,data)=>{
  const informationURL = BaseURL + `/blog/${key}`;
  const response = await axios.patch(informationURL,data);
  return response;
}

export const signIn = async(data) => {
  const informationURL = BaseURL + "/login"
  const response = await axios.post(informationURL, data);
  return response
} 

export const signUp = async(data) => {
  const informationURL = BaseURL + "/register"
  const response = await axios.post(informationURL , data);
  return response
}

export const getAllCategory = async () => {
  const informationURL = BaseURL +`/category`;
  const response = await axios.get(informationURL);
  return response;
};

export const addANewCategory = async (data) => {
  const informationURL = BaseURL +`/category`;
  const response = await axios.post(informationURL, data);
  return response;
};

export const generateSlug=async(key,data)=>{
  const informationURL = BaseURL + `/blogv2/generateslug/${key}`;
  const response = await axios.post(informationURL,data);
  return response;
}

export const SaveBlogV2=async(data)=>{
  const informationURL = BaseURL + `/blogv2`;
  const response = await axios.post(informationURL,data);
  return response;
}



export const UpdateBlogV2=async(key,data)=>{
  const informationURL = BaseURL + `/blogv2/${key}`;
  const response = await axios.patch(informationURL,data);
  return response;
}



export const GetBlogV2Data=async(key)=>{
  const informationURL = BaseURL + `/blogv2/${key}`;
  const response = await axios.get(informationURL);
  return response;
}


export const getAllBlogsv2 = async (page) => {
  const informationURL = BaseURL +`/blogv2?page=${page}`;
  const response = await axios.get(informationURL);
  return response;
};



export const DeleteBlogv2ById = async (key) => {
  const informationURL = BaseURL + `/blogv2/${key}`;
  const response = await axios.delete(informationURL);
  return response;
}